import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowRightIcon from "@heroicons/react/outline/ArrowRightIcon"

import configuration from "../configuration/home.json"
import { Seo } from "../components/Seo/Seo"
import { Footer, Navigation } from "../components/Marketing"
import { ArticleEntity } from "../entities/ArticleEntity"
import { CategoryEntity } from "../entities/CategoryEntity"
import { Article } from "../components/Marketing/Article"
import { Haiku, Separator, Tipeee } from "../components/article"

const Section = ({ children, title, description = null, label, url, more }) => (
  <div className="container mx-auto">
    <div className="relative px-4 pt-10 pb-10 sm:pt-16 sm:px-6 lg:pt-10 lg:px-8">
      <div className="relative max-w-6xl mx-auto">
        <div className="space-y-2">
          <h3 className="font-medium text-green-600 uppercase text-regular">
            {label}
          </h3>
          <h2 className="max-w-3xl text-3xl font-bold text-gray-800 sm:text-4xl">
            {title}
          </h2>
          {description && (
            <p className="max-w-3xl text-lg text-gray-600">{description}</p>
          )}
          <div className="flex items-center mt-8 text-lg font-medium text-green-600 group">
            <Link to={url}>{more}</Link>
            <ArrowRightIcon className="w-5 h-5 ml-4 transition-all duration-300 ease-in-out transform group-hover:translate-x-4" />
          </div>
        </div>
        <div className="grid max-w-lg gap-5 mx-auto mt-4 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
          {children}
        </div>
      </div>
    </div>
  </div>
)

const Context = ({ title, description, label, image, url, more }) => {
  const src = getImage(image)

  return (
    <div className="container mx-auto">
      <div className="relative px-4 pt-10 pb-10 lg:pb-20 sm:pt-16 sm:px-6 lg:pt-20 lg:px-8">
        <div className="relative grid max-w-6xl mx-auto md:grid-cols-2">
          <div className="relative items-center md:flex">
            <GatsbyImage
              image={src}
              className="rounded-lg h-72 md:h-auto"
              alt={`image de la catégorie ${label}`}
              title={`image de la catégorie ${label}`}
            />
          </div>

          <div className="mt-10 md:mt-0 md:flex md:items-center">
            <div className="md:pl-8 lg:pl-20">
              <h3 className="font-medium text-green-600 uppercase text-regular">
                {label}
              </h3>

              <h2 className="mt-2 text-3xl font-bold text-gray-800 md:text-4xl">
                {title}
              </h2>

              <p className="mt-8 text-lg text-gray-700">{description}</p>

              <div className="flex items-center mt-8 text-lg font-medium text-green-600 group">
                <Link to={url}>{more}</Link>
                <ArrowRightIcon className="w-5 ml-4 transition-all duration-300 ease-in-out transform -5 group-hover:translate-x-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Hero: React.FC = () => {
  return (
    <div className="relative overflow-hidden sm:h-screen">
      <div className="absolute inset-0 bottom-0 left-0 right-0 w-full h-full">
        <StaticImage
          alt="la plume du temple du haiku"
          title="la plume du temple du haiku"
          layout="constrained"
          imgClassName="object-cover object-bottom"
          className="w-full h-full"
          src="../assets/landing/hero.jpeg"
          quality={40}
        />
      </div>
      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        <main className="px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div>
                <h1 className="block text-sm font-semibold tracking-wide text-gray-600 uppercase sm:text-base lg:text-sm xl:text-base">
                  {configuration.hero.heading}
                </h1>
                <p
                  dangerouslySetInnerHTML={{ __html: configuration.hero.title }}
                  className="block mt-1 text-4xl font-extrabold tracking-tight sm:text-5xl xl:text-6xl"
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: configuration.hero.description,
                }}
                className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
              />
              <div className="pb-8 mt-8 sm:mx-auto sm:text-center lg:text-left">
                <div>
                  <a
                    href={"https://editeur.temple-du-haiku.fr"}
                    className="inline-flex items-center py-3 pl-6 text-base font-medium text-gray-800 transition duration-150 ease-in bg-white rounded-md shadow-xl sm:mt-3 hover:shadow-sm"
                  >
                    <span>
                      Découvrir les haïkus
                    </span>

                    <span className="pl-2 pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        stroke="none"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const FAQ = () => (
  <div className="bg-gray-50">
    <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-20 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h3 className="text-3xl font-extrabold text-gray-900">
            Questions fréquentes
          </h3>
          <p className="mt-4 text-lg text-gray-500">
            Nous nous efforçons de répondre au maximum de questions que vous
            pourriez vous poser. Nous allons bientôt mettre en place un service
            vous permettant de nous communiquer vos questionnement. En
            attendant, n'hésitez pas à passer par nos réseaux sociaux si besoin.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <div className="space-y-12">
            <dl>
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Est-ce que la plateforme est gratuite ?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                La plateforme est et restera gratuite pour les lecteurs et les
                rédacteurs.
              </dd>
            </dl>

            <dl>
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Suis-je le propriétaire de mes haïkus ?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Oui, vous pouvez éditer et supprimer vos haïkus quand vous le
                souhaitez.
              </dd>
            </dl>

            <dl>
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Puis-je publier de manière anonyme ?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Bien que nous vous encourageons de mettre votre vraie identité,
                il vous est permis de choisir un pseudonyme.
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ({
  pageContext,
  data,
}: PageProps<
  any,
  {
    articles: ArticleEntity[]
    articlesByCategory: { [x: string]: ArticleEntity[] }
    categories: CategoryEntity[]
  }
>) => {
  const blocks = data.allBlocksJson.nodes
  const articlesByCategories = pageContext.articlesByCategory
  const categories = pageContext.categories

  const $categories = categories.reduce((acculumation, category) => {
    acculumation[category.id] = {
      ...category,
    }

    return acculumation
  }, {})

  const $blocks = blocks.reduce((acculumation, node) => {
    acculumation[node.id] = node
    return acculumation
  }, {})

  const getBlocksWithOrder = configuration.order.map(order => {
    if (order.type === "haiku") return order
    if (order.type === "separator") return order

    return {
      type: order.type,
      value:
        order.type === "section" ? $blocks[order.id] : $categories[order.id],
    }
  })

  return (
    <>
      <Seo
        title={configuration.seo.title}
        description={configuration.seo.description}
      />
      <Navigation showLinksDirectlyForSeo />
      <Hero />

      {getBlocksWithOrder.map(({ type, value }) => {
        if (type === "separator") return <Separator />
        if (type === "haiku")
          return (
            <Haiku
              key={value.content}
              haiku={value.content}
              author={{ fullName: value.author.name, url: value.author.url }}
              noMarginTop={value.style?.noMarginTop}
            />
          )

        if (type === "category")
          return (
            <Section
              label={value.home.label}
              key={value.name}
              title={value.home.title}
              description={value.home.description}
              url={`${value.url}`}
              more={value.home.more}
            >
              {articlesByCategories[value.id].slice(0, 3).map(article => (
                <Article
                  id={article.url}
                  key={article.url}
                  url={article.url}
                  reading={article.reading}
                  image={article.thumb}
                  title={article.display__title}
                  description={article.seo__description}
                  date={article.updated_at}
                  category={value.name}
                  author={article.author}
                />
              ))}
            </Section>
          )

        if (type === "exemples-de-haiku")
          return (
            <Section
              label={"Exemples de haïku"}
              title={"Découvrez notre sélection d'exemples de haïku"}
              description={
                "Il est fort probable que vous recherchiez des exemples de haïku dans des thèmes bien précis comme la nature, l'amour, le printemps, etc… Nous avons sélectionné manuellement un certain nombre de haïkus dans les thèmes les plus populaire, j'espère que vous les apprécierez 🙇‍♂️."
              }
              url={`/exemples-de-haiku/`}
              more={"Voir pleins d'exemples de haïku ?"}
            >
              {articlesByCategories["exemples-de-haiku"].map(article => (
                <Article
                  id={article.url}
                  key={article.url}
                  url={article.url}
                  reading={article.reading}
                  image={article.thumb}
                  title={article.display__title}
                  description={article.seo__description}
                  date={article.updated_at}
                />
              ))}
            </Section>
          )

        if (type === "section")
          return (
            <Context
              label={value.label}
              title={value.title}
              description={value.description}
              url={value.url}
              more={value.more}
              image={value.illustration}
            />
          )
      })}

      <Separator />
      <Tipeee />
      <Footer showLinks />
    </>
  )
}

export const pageQuery = graphql`
  query {
    allBlocksJson {
      nodes {
        id
        description
        label
        title
        url
        more
        illustration {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 600
              quality: 50
            )
          }
        }
      }
    }
  }
`
